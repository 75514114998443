#root{
    display: grid;
    justify-content: center;
    align-items: center;
}

#notWorking{
    height: 100vh;
    width: 100%;
}

.form-label{
    color: rgb(36, 65, 107);
    font-weight: 500;
}

#carouselExampleControls img{
    height: 480px;
    object-fit: cover;
}

.carousel-title{
    font-size: 60px;
    color: rgb(255, 139, 48);
}

.carousel-btn{
    background-color: white;
}

@media (min-width: 200px){
    .carousel-title{
        font-size: 30px;
        margin-bottom: 50px;
    }
    .carousel-btn{
        margin-bottom: 80px;
    }
}

@media (min-width: 350px){
    .carousel-title{
        font-size: 35px;
        margin-bottom: 50px;
    }
    .carousel-btn{
        margin-bottom: 80px;
    }
}

@media (min-width: 500px){
    .carousel-title{
        font-size: 40px;
        margin-bottom: 50px;
    }
    .carousel-btn{
        margin-bottom: 80px;
    }
}

@media (min-width: 900px){
    .carousel-title{
        margin-bottom: 50px;
    }
    .carousel-btn{
        margin-bottom: 80px;
    }
}

@media (min-width: 1280px){
    .carousel-title{
        margin-bottom: 50px;
    }
    .carousel-btn{
        margin-bottom: 80px;
    }
}